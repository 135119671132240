@media only screen and (max-width: 1100px) {
  :root {
    --font: 'Roboto Mono', monospace;
    --font_size_1: 1rem;
    --font_size_0_8: 0.8rem;
    --font_size_1_2: 1rem;

    --font_size_2: 1.1rem;
    --font_size_2_2: 1.2rem;
    --font_size_3: 1.3rem;
    --font_size_3_2: 2rem;
    --font_size_3_3: 1.5rem;
    --font_size_4: 3rem;

    --font_weight_1: 700;
    --font_weight_2: 500;
    --font_weight_3: 400;

    --inactive_color: #808080;
    --black_color: #000000;
  }

  .logo {
    order: -1;
    height: 100px;
    width: 30rem;
  }

  .nav_item {
    height: 2.5rem;
  }

  .header {
    flex-direction: column;
    position: static;
    height: 70%;
  }

  /* .bg-main {
    height: 40%;
  } */

  .navigation {
    flex-direction: column;
    align-items: center;
    font-size: var(--font_size_1);
    font-weight: var(--font_weight_100);
    justify-content: start;
  }

  .nav_panel {
    flex-direction: column;
  }

  .col-6.col-md-3 {
    border: 1px solid black;
  }

  .collection-name {
    font-weight: var(--font_weight_3);
    font-size: var(--font_size_3);
  }

  .cart-all {
    width: 90%;
  }

  .input-section {
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .cart-items {
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .cart-input {
    width: 100%;
    margin: 0.5rem 0rem;
    outline: none;
    height: 3rem;
    border: 1px solid #0000001c;
    border-radius: var(--border-radius);
    font-size: var(--font_size_1_2);
  }

  .input-info {
    font-size: var(--font_size_0_8);
    font-weight: var(--font_weight_3);
    color: gray;
  }
}

@media only screen and (max-width: 1024px) {
  .modal-card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .modal-card-slider {
    width: 100%;
  }

  .slider-img {
    display: flex;
    justify-content: center;
    height: 50vh;
  }

  .modal-card-info {
    height: 30%;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-btn-soldout-modal {
    width: 8rem;
  }

  .card-btn-buy-modal {
    /* width: 10rem; */
  }

  .card-title-modal {
    margin: 0rem 0rem 0.5rem 0rem;
    font-weight: var(--font_weight_1);
    font-size: var(--font_size_2);
  }

  .card-description-modal {
    font-weight: var(--font_weight_3);
    font-size: var(--font_size_1_2);
    overflow-y: scroll;
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .size-grid {
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .size-btn {
    height: 2rem;
    width: 2rem;
    margin: 0rem 1.2rem 0rem 0rem;
    transition: 0.3s;
  }

  .pick {
    color: var(--black_color);
    border: 1px solid var(--black_color);
    font-size: var(--font_size_2_2);
  }

  .col-6.col-md-3 {
    border: 1px solid black;
  }
}

@media only screen and (max-width: 720px) {
  .col-6.col-md-3 {
    border: 1px solid black;
  }
}

@media only screen and (max-width: 717px) {
  .col-6.col-md-3 {
    border: 1px solid black;
  }

  .modal-card-info {
    height: 35%;
    width: 100%;
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 430px) {
  .modal-card-info {
    height: 40%;
    width: 100%;
    padding: 0.5rem;
  }

  .col-6.col-md-3 {
    border: 1px solid black;
  }
}

@media only screen and (max-width: 375px) {
  .modal-card-info {
    height: 40%;
    width: 100%;
    padding: 0.5rem;
  }

  .col-6.col-md-3 {
    border: 1px solid black;
  }

  .header {
    flex-direction: column;
    position: static;
    height: 80%;
  }

  /* .bg-main {
    height: 40%;
  } */
}
