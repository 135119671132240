.logo {
  order: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;

  height: 100%;
  width: 25rem;
}

.logo img {
  z-index: 10;
  max-width: 50%;
}

.logo-burger {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 25rem;
  flex-grow: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo-burger img {
  z-index: 10;
  max-width: 50%;
}

.header {
  z-index: 10;
  position: fixed;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  background-color: #00000080;
  width: 100%;
  height: 6rem;
}

.header-burger {
  z-index: 10;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;

  top: 0;
  left: 0;
  background-color: #00000080;
  width: 100%;
  height: 5rem;
  padding: 0rem 1rem;
}

.burger {
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;

  height: 100%;
  width: 2rem;
}

.burger-panel {
  z-index: 10;
  position: fixed;
  align-items: center;
  /* justify-content: space-around; */
  display: flex;
  flex-direction: column;

  top: 5rem;
  left: 0;
  color: #ffffff;
  font-size: var(--font_size_1);
  font-weight: var(--font_weight_2);
  overflow-y: auto;
  background-color: #00000080;
  width: 10rem;
  height: 100%;
}

.navigation {
  flex-direction: row;
  color: #ffffff;
  font-size: var(--font_size_1);
  font-weight: var(--font_weight_2);
  height: 100%;
  display: flex;
  justify-content: center;
}

.nav_panel {
  width: 600px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.nav_item {
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 1rem;
  height: 3rem;
  transition: 0.3s;
}

.nav_item.burger {
  width: 90%;
}

.collection-name {
  font-weight: var(--font_weight_3);
  font-size: var(--font_size_4);
}

.col-6.col-md-3 {
  user-select: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0rem;
  border: 1rem solid black;
}

.sold-out {
  opacity: 0.9;
  position: absolute;
  color: #000000cd;
  font-weight: var(--font_weight_1);
  font-size: var(--font_size_3_3);
  /* text-shadow: 1px 1px 2px #000000; */
  white-space: nowrap;
  top: 35%;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
}

.photo-block {
  width: 100%;
  aspect-ratio: 16/25;
}

.photo-block.opacity-photo {
  opacity: 0.45;
}

.card-photo {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.card-details {
  height: 10rem;
  padding: 0.5rem;
}

.card-new-price-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-new-price-info * {
  margin: 0rem 0.5rem 0rem 0rem;
}

.card-price {
  font-weight: var(--font_weight_4);
  font-size: var(--font_size_1_2);
}

.card-price-new {
  font-weight: var(--font_weight_4);
  font-size: var(--font_size_1_2);
  color: #ff0000;
}

.card-price-old {
  text-decoration: line-through;
  font-weight: var(--font_weight_4);
  font-size: var(--font_size_1_2);
}

.card-discount {
  padding: 0.2rem;
  background-color: #ff0000;
  color: white;
  transform: skew(-10deg);
  font-weight: var(--font_weight_2);
}

.card-price-modal {
  font-weight: var(--font_weight_4);
  font-size: var(--font_size_2_2);
}

.card-price-new-modal {
  font-weight: var(--font_weight_4);
  font-size: var(--font_size_2_2);
  color: #ff0000;
}

.card-price-old-modal {
  text-decoration: line-through;
  font-weight: var(--font_weight_4);
  font-size: var(--font_size_2);
}

.card-discount-modal {
  padding: 0.2rem;
  background-color: #ff0000;
  color: white;
  transform: skew(-10deg);
  font-weight: var(--font_weight_4);
}

.card-title {
  height: 5rem;
  font-weight: var(--font_weight_1);
  font-size: var(--font_size_1_2);
}

.card-title-modal {
  margin: 0rem 0rem 2rem 0rem;
  font-weight: var(--font_weight_1);
  font-size: var(--font_size_3);
}

.card-description-modal {
  font-weight: var(--font_weight_3);
  font-size: var(--font_size_1_2);
  margin: 0rem 0rem 2rem 0rem;
}

.configurate_group {
  cursor: pointer;
  user-select: none;
}

.card-before-purchase {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-btn-soldout-modal {
  user-select: none;
  height: 3rem;
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #ff0000;
  border: 1px solid #000000;
  border-radius: var(--border-radius);
  font-size: var(--font_size_3);
  font-weight: var(--font_weight_1);
  padding: 0rem 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.1s;
}

.card-btn-buy-modal {
  user-select: none;
  height: 3rem;
  /* width: 12rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border: 1px solid #000000;
  border-radius: var(--border-radius);
  color: #ffffff;
  font-size: var(--font_size_3);
  font-weight: var(--font_weight_1);
  padding: 0rem 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.1s;
}

.card-btn-buy-modal:active {
  border: 1px solid #000000;
  background-color: white;
  color: #000000;
}

.ReactModal__Content {
  @include respond(sm) {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto !important;
  }
}

.close-modal {
  z-index: 20;
  position: fixed;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.active {
  border-radius: var(--border-radius);
  border: 1px solid rgb(255, 255, 255);
}

.underline {
  text-decoration: underline;
}

.cart-modal {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0.2rem 0.5rem;
  /* width: 4rem; */
  cursor: pointer;
  background-color: #0000009c;
  /* border: 1px solid #0000009c; */
  border-radius: var(--border-radius);
  z-index: 11;
  /* margin-top: auto; */
}

.shopping-cart {
  display: flex;
  justify-content: center;
  margin: 0.5rem;
}

.cart-all {
  width: 50%;
}

.cart-items {
  overflow-y: scroll;
  max-height: 50vh;
  margin: 0rem 0rem 1rem 0rem;
}

.shopping-cart-item {
  padding: 1rem 0rem;
  border-bottom: 1px dashed var(--black_color);
  font-weight: var(--font_weight_2);
  font-size: var(--font_size_1_2);
  display: flex;
  flex-direction: column;
}

.shopping-cart-title-modal {
  font-size: var(--font_size_3_2);
  font-weight: var(--font_weight_1);
}

.item-first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cart-item-size {
  font-size: var(--font_size_1);
  font-weight: var(--font_weight_3);
  color: gray;
}

.cart-item-price {
  white-space: nowrap;
}

.cart-btn-remove {
  margin: 0rem 0.5rem 0rem 0rem;
  width: 3rem;
  height: 1.5rem;

  background-color: var(--red_color);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-section {
  margin: 0rem 0rem 1rem 0rem;
}

.cart-inputs {
  margin: 1rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #0000001c;
  border-radius: var(--border-radius);
  padding: 0.5rem;
}

.input-name {
  font-size: var(--font_size_1_2);
  font-weight: var(--font_weight_1);
}

.input-info {
  font-size: var(--font_size_1);
  font-weight: var(--font_weight_3);
  color: gray;
}

.cart-input {
  width: 100%;
  margin: 0.5rem 0rem;
  padding: 0rem 0.5rem;
  outline: none;
  height: 3rem;
  border: 1px solid #0000001c;
  border-radius: var(--border-radius);
  font-size: var(--font_size_1_2);
}

.cart-btn-checkout {
  user-select: none;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border: 1px solid #000000;
  border-radius: var(--border-radius);
  color: #ffffff;
  font-size: var(--font_size_3);
  font-weight: var(--font_weight_1);
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.1s;
}

.cart-btn-checkout:active {
  border: 1px solid #000000;
  background-color: white;
  color: #000000;
}

.cart-counter {
  min-width: 2rem;
  padding: 0.1rem;
  margin: 0.1rem;
  border-radius: 5px;
  background-color: var(--red_color);
  color: white;
  font-size: var(--font_size_1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-main {
  top: 5rem;
  width: 100%;
  height: 90%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
  color: white;
  position: relative;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.collection-name {
  font-weight: var(--font_weight_3);
  font-size: var(--font_size_4);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bg-main-icon {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.modal-card {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.modal-card-info {
  width: 50%;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-card-slider {
  width: 50%;
}

.slider-img {
  display: flex;
  justify-content: center;
  height: 99.8vh;
}

.big-modal-photo {
  height: 100vh;
}

.size-grid {
  display: flex;
  flex-direction: row;
  margin: 0rem 0rem 2rem 0rem;
}

.size-btn {
  user-select: none;
  height: 3rem;
  width: 3rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff00;
  color: var(--inactive_color);
  font-size: var(--font_size_2);
  cursor: pointer;
  display: flex;
  margin: 0rem 1.5rem 0rem 0rem;
  transition: 0.3s;
}

.pick {
  color: var(--black_color);
  border: 1px solid var(--black_color);
  border-radius: var(--border-radius);
  font-size: var(--font_size_2_2);
}

.error {
  font-size: var(--font_size_1_2);
  font-weight: var(--font_weight_1);

  color: var(--red_color);
  border: 2px solid var(--red_color);
  border-radius: var(--border-radius);
  text-align: center;
  margin: 0.5rem 0rem;
}

.footer {
  margin: 5rem 0rem 0rem 0rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}

.footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0rem 2rem;
  background-color: wheat;
  margin: 0rem 1rem;
}

.footer-logo {
  height: 10vh;
  width: 10vh;
}

.modal-area {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0rem 1rem 0rem;
  width: 15rem;
  height: 5rem;
  border: 1px solid white;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
}

#cdek-map {
  margin: 1rem 0rem 3rem 0rem;
  width: 100%;
  height: 400px;
}
